import React from "react"
// import { Link } from "gatsby"
import Homepage from '../components/Homepage'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import StoryblokService from '../utils/storyblok-service'
import CloudinaryVideo from "./../components/cloudinaryvideoembed"

export default class extends React.Component {

  state = {
    playVid: false,
    story: {
       content: this.props.data.story ? JSON.parse(this.props.data.story.content) : {}
    }
  }

  async getInitialStory() {
    StoryblokService.setQuery(this.props.location.search)
    let { data: { story } } = await StoryblokService.get(`cdn/stories/${this.props.data.story.full_slug}`)
    return story
  }

  async componentDidMount() {
    
    
    let story = await this.getInitialStory()
    if(story.content) this.setState({ story })
    setTimeout(() => StoryblokService.initEditor(this), 200)
    // setTimeout(() => document.body.classList.add("invert-header"), 500)
  }
  

  render() {
    this.vidRef = React.createRef()
    let video_ID = this.state.story.content.body[0].render_video_ID
    return (
       <Layout location={this.props.location}>
        <SEO title="Teaser" />
        <section>
            <div className="product-video-large">
                <div className="container">
                <div className="product-video-large__video-wrapper">
                    <CloudinaryVideo
                    publicId={`ykk/${video_ID}`}
                    disableFill={true}
                    disableGif={true}
                    autoPlay={false}
                    clickToPlay={true}
                    class_list="product-video-large__cloudinary-embed"
                    />
                </div>
                </div>
            </div>
        </section>
      </Layout>
    )
  }
}

export const query = graphql`
  {
    story: storyblokEntry(full_slug: { eq: "teaser-film" }) {
      name
      content
      full_slug
      uuid
    }
  }
`
