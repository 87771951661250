import React, {useEffect} from "react"
import DynamicComponent from "./DynamicComponent"
import SbEditable from 'storyblok-react'
import gsap from "gsap"
import PageLoad from '../components/HomepageLoader'
import HomepageNavMobile from '../components/HomepageNavMobile'

const Page = ({blok}) => {
  const content = blok.homepagelinks && blok.homepagelinks.map(childBlok => <DynamicComponent blok={childBlok} key={childBlok._uid}/>)

  useEffect(() => {
    // GSAP prevent flashing
    gsap.to("body", 0, { css:{visibility: "visible"} });

    // GSAP Timeline
    const tl = gsap.timeline();

    tl.from(".site-header__inner button, .site-header__logo, .request-a-sample-btn__text-desktop, .request-a-sample-btn__text-mobile, .request-a-sample-btn__icon, .testimonials-link", 1.5, {
      y: -100,
      ease: 'expo.inOut',
      delay: 0.7,
      stagger: {
        amount: 0.3
      }
    })
    .to(".homepage-loader__segment", 1.8, {
      height: 0,
      ease: 'expo.inOut',
      stagger: 0.2,
      delay: -0.6
    })
    .to(".reveal-text", 0.1, {
      opacity: 1,
      ease: 'expo.Out',
      delay: -2
    })
    .from(".reveal-text__animate", 0.6, {
      y: 80,
      ease: 'sine.Out',
      stagger: 0.15,
      delay: -1.8
    })
    .from(".homepage-nav-mobile__bg-video", 0.6, {
      opacity: 0,
      ease: 'expo.Out'
    })
    .from(".tag-element__text span", 2, {
      opacity: 0,
      ease: 'expo.Out',
      stagger: 0.3,
      delay: -1.8
    })
  }, []);

return (
  <SbEditable content={blok}>
    <PageLoad/>
    <div className="homepage-nav">
      {content}
    </div>
    <HomepageNavMobile homepagelinks={blok.homepagelinks}/>
  </SbEditable>)
}

export default Page
