import React from 'react';

const HomepageLoader = () => {
  return (
    <div className="homepage-loader">
      <div className="homepage-loader__segment"></div>
      <div className="homepage-loader__segment"></div>
      <div className="homepage-loader__segment"></div>
      <div className="homepage-loader__segment"></div>
    </div>
  );
};

export default HomepageLoader;
